/* Base Styles */
.contact {
    width: 100%;
    height: auto;
    background: #fce3fe;
    padding-top: 120px; 
    padding-bottom: 120px;
    margin-bottom: 100px;
  }
  
  .contact-container {
    width: 100%;
    max-width: 580px;
    height: auto;
    background: white;
    margin: auto;
    padding: 40px 60px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h1 {
    margin: 20px 0;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
  
  .loginsignup-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
  
  .loginsignup-fields input {
    height: 50px;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #c9c9c9;
    outline: none;
    color: #5c5c5c;
    font-size: 16px;
    border-radius: 4px;
  }
  
  .loginsignup-fields input:focus {
    border-color: #ff4141;
    transition: border-color 0.3s;
  }
  
  .contact-container button {
    width: 100%;
    height: 50px;
    color: white;
    background: #ff4141;
    margin-top: 30px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .contact-container button:hover {
    background: #ff2c2c;
  }
  
  /* Mobile (Up to 480px) */
  @media only screen and (max-width: 480px) {
    .contact {
      padding-top: 70px;
      padding-bottom: 60px;
    }
  
    .contact-container {
      width: 90%;
      padding: 15px;
    }
  
    .loginsignup-fields input {
      font-size: 14px;
      padding-left: 15px;
    }
  
    .contact-container h1 {
      font-size: 20px;
    }
  
    .contact-container button {
      width: 100%;
      font-size: 16px;
      height: 45px;
    }
  }
  
  /* Tablet and Smaller Screens (Up to 768px) */
  @media only screen and (max-width: 768px) {
    .contact-container {
      width: 90%;
      padding: 25px;
    }
  
    .contact {
      padding-top: 90px;
      padding-bottom: 80px;
    }
  
    .loginsignup-fields input {
      font-size: 15px;
    }
  
    .contact-container h1 {
      font-size: 24px;
    }
  
    .contact-container button {
      font-size: 18px;
      height: 50px;
    }
  }
  
  /* Large Screens (1024px and Up) */
  @media only screen and (min-width: 1024px) {
    .contact-container {
      width: 40%;
      padding: 40px 60px;
    }
  
    .contact {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  
    .contact-container h1 {
      font-size: 32px;
    }
  
    .contact-container button {
      font-size: 20px;
      height: 55px;
    }
  }
  
  /* For very large screens (larger than 1200px) */
  @media only screen and (min-width: 1200px) {
    .contact-container {
      width: 35%; /* Slightly narrower container for larger screens */
    }
  }
  