/* Shared styles remain the same */
.hero {
  /* max-width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 20px auto;
  max-width: 1200px; /* Centered layout with a consistent max width */
  width: 90%; /* Allows adaptability for smaller screen widths */
}

.hero-section {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: top;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.hero-section:hover {
  transform: scale(1.02);
}

.hero-left {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.hero-left a {
  margin-top: auto;
  margin-bottom: 4px;
}

.hero-left h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
}

.hero-left p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #666;
}

/* Order Now Button Styles */
.order-now-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  white-space: nowrap;
  min-width: 150px;
}

.order-now-btn:hover {
  background-color: #0056b3;
}

.hero-right img {
  max-width: 300px;
  border-radius: 10px;
}

/* Individual temple styles */
.hero-section.ram {
  background-color: #fff8e1;
}

.hero-section.vaishno {
  background-color: #fff8e1;
}

.hero-section.baba-dham {
  background-color: #f0f4f8;
}

.hero-section.mahakal {
  background-color: #e8f5e9;
}

/* General Mobile Responsive Styles */
@media (max-width: 1024px) {
  .hero {
    gap: 1.5rem;
  }

  .hero-section {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .hero-left {
    max-width: 100%;
  }

  .hero-left h2 {
    font-size: 1.6rem;
  }

  .hero-left p {
    font-size: 0.95rem;
  }

  .order-now-btn {
    justify-content: center;
    font-size: 0.9rem;
    padding: 6px 12px;
    min-width: 150px;
  }

  .hero-right img {
    max-width: 100%;
    margin-top: 10px;
  }
}

/* Small Tablets */
@media (max-width: 768px) {
  .hero-section {
    padding: 12px;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .hero-left h2 {
    font-size: 1.4rem;
  }

  .hero-left p {
    font-size: 0.85rem;
  }

  .order-now-btn {
    padding: 6px 12px;
    font-size: 0.85rem;
    min-width: 140px;
  }
}

/* Small Phones */
@media (max-width: 480px) {
  .hero {
    margin: 10px;
  }

  .hero-section {
    padding: 10px;
  }

  .hero-left h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .hero-left p {
    font-size: 0.8rem;
    margin-bottom: 8px;
  }

  .order-now-btn {
    padding: 4px 8px;
    font-size: 0.8rem;
    min-width: 120px;
  }
}

/* Extra Small Phones */
@media (max-width: 360px) {
  .hero {
    margin: 5px;
  }

  .hero-section {
    padding: 8px;
    border-radius: 5px;
  }

  .hero-left h2 {
    font-size: 1rem;
  }

  .hero-left p {
    font-size: 0.7rem;
  }

  .order-now-btn {
    padding: 4px 8px;
    font-size: 0.7rem;
    min-width: 100px;
  }
}

/* Dashboard Responsiveness */
@media (min-width: 1025px) and (max-width: 1440px) {
  .hero {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    /* max-width: 100%;
    flex-wrap: wrap; */
  }

  .hero-section {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }

  .hero-left {
    max-width: 45%; /* Increased content area */
  }

  .hero-right img {
    max-width: 350px;
  }
}

/* Large Screens (Above 1440px) */
@media (min-width: 1441px) {
  .hero {
    margin: 40px auto;
    max-width: 1400px;
  }

  .hero-left {
    max-width: 50%;
  }

  .hero-right img {
    max-width: 400px;
  }
}
