.popular {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 50px auto;
  padding: 0 20px; /* Add padding for extra spacing on smaller screens */
  max-width: 1200px; /* Centered layout */
}

.popular h1 {
  color: #171717;
  font-size: 42px;
  font-weight: 600;
  text-align: center;
}

.popular hr {
  width: 150px;
  height: 4px;
  border-radius: 5px;
  background: #252525;
}

.popular-item {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  gap: 20px;
  width: 100%; /* Ensures grid spans full width */
  /* justify-items: center; */
  justify-content: center;
}

/* Tablet View */
@media only screen and (max-width: 1024px) {
  .popular {
    margin: 40px auto;
    padding: 0 15px;
  }

  .popular h1 {
    font-size: 36px;
  }

  .popular hr {
    width: 120px;
    height: 3px;
  }

  .popular-item {
    gap: 15px;
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); /* Adjust grid for smaller screens */
  }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
  .popular {
    margin: 30px auto;
    padding: 0 10px;
  }

  .popular h1 {
    font-size: 28px;
  }

  .popular hr {
    width: 100px;
    height: 3px;
  }

  .popular-item {
    gap: 15px;
    grid-template-columns: repeat(
      auto-fit,
      minmax(180px, 1fr)
    ); /* Adjust for narrower columns */
  }
}

/* Small Mobile View */
@media only screen and (max-width: 480px) {
  .popular {
    margin: 20px auto;
    padding: 0 10px;
  }

  .popular h1 {
    font-size: 24px;
  }

  .popular hr {
    width: 80px;
  }

  .popular-item {
    gap: 10px;
    grid-template-columns: 1fr; /* Single-column layout */
  }
}

/* Large Screens (Above 1440px) */
@media only screen and (min-width: 1441px) {
  .popular {
    max-width: 1400px;
    margin: 60px auto;
  }

  .popular h1 {
    font-size: 48px;
  }

  .popular hr {
    width: 180px;
    height: 5px;
  }

  .popular-item {
    gap: 25px;
    grid-template-columns: repeat(
      auto-fit,
      minmax(300px, 1fr)
    ); /* Larger columns for bigger screens */
  }
}
