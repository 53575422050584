.cartitems{
    margin: 100px 170px;
}
.cartitems hr{
    height: 3px;
    background: #e2e2e2;
    border: 0;
}
.cartitems-format-main{
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 20px 0px;
    color: #454545;
    font-size: 18px;
    font-weight: 600;
}
.cartitems-format{
    font-size: 17px;
    font-weight: 500;
}
.cart-icon-product-icon{
    height: 62px;
}
.carticon-remove-icon{
    width: 15px;
    margin: 0px 40px;
    cursor: pointer;
}
.cartitems-quantity{
    width: 64px;
    height: 50px;
    border: 2px solid #ebebeb;
    background: white;
}
.cartitems-down{
    display: flex;
    gap: 50px;
    margin: 100px 0px;
}
.cartitems-total{
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 40px;
    margin-right: 40px;
}
.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;

}
.cartitems-total button {
    width: 262px;
    height: 58px;
    outline: none;
    border: none;
    background: #ff5a5a;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.cartitems-promocode{
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}
.cartitems-promocode p{
    color: #555;
}
.cartitems-promobox{
    display: flex;
    width: 504px;
    margin-top: 15px;
    height: 58px;
}
.cartitems-promobox input{
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    width: 330px;
    height: 50px;
}
.cartitems-promobox button{
    width: 170px;
    height: 58px;
    font-size: 16px;
    background: black;
    color: white;
    cursor: pointer;

}
.cartitems-format-main-mobile{
    display: none;
}
.promobox-input{
  background: #eaeaea;
  padding-left: 20px;
}

@media only screen and (max-width: 768px) {
    .cartitems-format-main{
    display: none;
}
.cartitems-format-main-mobile{
    display: block;
}
.cartitems-format-main-mobile-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.margin-top{
    margin-top: 50px;
}
.give-margin{
    margin-bottom: 50px;
}
.carticon-remove-icon{
    width: auto;
    margin: 0;
    height: 18px;
}
 .cartitems-down{
    display: block;
 }
 .cartitems{
    margin: 100px 40px;
 }
 .cartitems-total{
    margin-bottom: 50px;
 }
 .cartitems-promobox{
    width: 270px;
 }
 .cartitems-promobox button{
    border: none;
    width: 125px;
 }
 .cartitems-promobox input{
    border: none;
    width: 130px;
 }
}