.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 20px;
    background-color: #f9f9f9;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footer-logo img {
    height: 60px;
    width: 60px;
}

.footer-logo p {
    color: #383838;
    font-size: 1.8rem;
    font-weight: 700;
}

.footer-links {
    display: flex;
    list-style: none;
    gap: 30px;
    font-size: 1rem;
    color: #252525;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-links a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #007bff;
}

.footer-social-icon {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.footer-icons-container {
    padding: 8px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    transition: box-shadow 0.3s;
}

.footer-icons-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer-icons-container img {
    height: 30px;
    width: 30px;
}

.footer-copyright {
    text-align: center;
    font-size: 0.9rem;
    color: #555555;
    margin-top: 20px;
}

.footer-copyright hr {
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 2px;
    background: #c7c7c7;
}

@media only screen and (max-width: 768px) {
    .footer {
        gap: 15px;
        padding: 10px;
    }

    .footer-logo img {
        height: 40px;
        width: 40px;
    }

    .footer-logo p {
        font-size: 1.5rem;
    }

    .footer-links {
        flex-direction: column;
        gap: 10px;
    }

    .footer-links li {
        text-align: center;
    }

    .footer-social-icon {
        gap: 10px;
    }

    .footer-icons-container img {
        height: 25px;
        width: 25px;
    }

    .footer-copyright {
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 1200px) {
    .footer {
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 60px;
        gap: 50px;
    }

    .footer-links {
        gap: 50px;
    }

    .footer-social-icon {
        gap: 20px;
    }
}
