.breadcrum{
    display: flex;
    color: #5e5e5e;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    margin: 60px 170px;
    text-transform: capitalize;
}
@media only screen and (max-width: 768px) {
 .breadcrum{
    margin: 15px 30px;
    font-size: 14px;
 }
}