.thankyou {
    margin: 30px 100px;
    padding: 20px 60px;
}

.container-info {
    margin: 10px;
}

.container-info h2 {
    color: #3d3d3d;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 5px;
}

.container-info p {
    font-size: 20px;
    font-weight: 500;
}

.container-info a {
    text-decoration: none;
}

.order-now-btn-1 {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    width: 280px;
    height: 60px;
    border-radius: 75px;
    margin-top: 20px;
    color: #fff;
    background: #ff4141;
    font-size: 20px;
    font-weight: 500;
}

/* Responsive Styles */
@media only screen and (max-width: 1024px) {
    .thankyou {
        margin: 20px;
        padding: 15px 30px;
    }

    .container-info h2 {
        font-size: 28px;
    }

    .container-info p {
        font-size: 18px;
    }

    .order-now-btn-1 {
        width: 240px;
        height: 50px;
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px) {
    .thankyou {
        padding: 15px 20px;
        margin: 10px;
    }

    .container-info h2 {
        font-size: 24px;
    }

    .container-info p {
        font-size: 16px;
    }

    .order-now-btn-1 {
        width: 200px;
        height: 45px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .thankyou {
        padding: 10px;
        margin: 5px;
    }

    .container-info h2 {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .container-info p {
        font-size: 14px;
    }

    .order-now-btn-1 {
        width: 180px;
        height: 40px;
        font-size: 14px;
    }
}

/* Additional Mobile Optimization */
@media only screen and (max-width: 360px) {
    .thankyou {
        padding: 10px;
        margin: 5px;
    }

    .container-info h2 {
        font-size: 18px;
    }

    .container-info p {
        font-size: 13px;
    }

    .order-now-btn-1 {
        width: 160px;
        height: 35px;
        font-size: 12px;
    }
}

/* Dashboard Specific Adjustments */
@media only screen and (max-width: 1280px) {
    .thankyou {
        margin: 20px 60px;
        padding: 20px 50px;
    }

    .container-info h2 {
        font-size: 32px;
    }

    .container-info p {
        font-size: 18px;
    }

    .order-now-btn-1 {
        width: 260px;
        height: 55px;
        font-size: 18px;
    }
}

@media only screen and (max-width: 1200px) {
    .thankyou {
        margin: 20px;
        padding: 18px 40px;
    }

    .container-info h2 {
        font-size: 30px;
    }

    .container-info p {
        font-size: 16px;
    }

    .order-now-btn-1 {
        width: 240px;
        height: 50px;
        font-size: 16px;
    }
}

/* Dashboard Elements: Sidebars and Cards */
@media only screen and (max-width: 1024px) {
    .dashboard-sidebar {
        width: 240px;
    }

    .dashboard-main-content {
        margin-left: 250px;
        padding: 20px;
    }

    .card {
        width: 100%;
        margin-bottom: 15px;
        padding: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .dashboard-sidebar {
        width: 200px;
        display: none;
    }

    .dashboard-main-content {
        margin-left: 0;
        padding: 15px;
    }

    .card {
        width: 100%;
        margin-bottom: 10px;
        padding: 15px;
    }
}
