.navbar{
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0px 1px 3px -2px black;
}
.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}
.nav-logo p{
    color: #171717;
    font-size: 38px;
    font-weight: 600;
}
.nav-menu{
    display: flex;
    align-items: center;
    list-style-type: none;
    color: #626262;
    gap: 50px;
    font-size: 20px;
    font-weight: 500;
}
.nav-menu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}
.nav-menu hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #ff4141;
}
.nav-login-cart{
    display: flex;
    align-items: center;
    gap: 45px;
}
.nav-login-cart button{
    width: 157px;
    height: 58px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: white;
    cursor: pointer;  
}
.nav-login-cart button:active{
    background: #f3f3f3;
}
.nav-cart-count{
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}
 .login-btn{
    display: none;
  }
@media only screen and (max-width: 768px) {
  .nav-menu {
    display: none;
  }
  .login-btn{
    display: none;
  }
  .nav-logo p{
    color: #171717;
    font-size: 26px;
  }
  .nav-logo img{
    height: 30px;
    width: 32px;
  }
}